import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import VideoLibraryRoundedIcon from "@material-ui/icons/VideoLibraryRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactSupportRoundedIcon from "@material-ui/icons/ContactSupportRounded";
import NetworkWifiRoundedIcon from "@material-ui/icons/NetworkWifiRounded";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import AddToQueueRoundedIcon from "@material-ui/icons/AddToQueueRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import AssignmentTurnedInRoundedIcon from "@material-ui/icons/AssignmentTurnedInRounded";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { useHistory } from "react-router-dom";

import { NotificationsMenu } from "../UIElements/NotificationsMenu";
import { getAppStatusIcon, getAppStatusTitle } from "../../util/getAppStatus";
import { AboutTrialFormPopover } from "../../../general/components/unauthenticated/AboutTrialFormPopover";
import { DesktopNavMenu } from "./DesktopNavMenu";
import { AuthContext } from "../../context/authContext";
import { SettingsContext } from "../../context/settingsContext";
import { addToHomescreenPrompt } from "../../util/addToHomescreenPrompt";
import { exportCustomerssData } from "../../../customer/api/customersAPI";
import { BeforeInstallPromptEvent } from "../../../App";
import "./DesktopNavLinksStyles.scss";

export interface DesktopNavLinksProps {
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  needUpdate: boolean;
  needCriticalUpdate: boolean;
  updateAvailable: boolean;
}

export const DesktopNavLinks = (props: DesktopNavLinksProps) => {
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    needCriticalUpdate,
    needUpdate,
    updateAvailable,
  } = props;
  const auth = useContext(AuthContext);
  const settingsCtx = useContext(SettingsContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<
    null | HTMLButtonElement | HTMLSpanElement
  >(null);
  const isConfigManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.configManager || false;

  return (
    <ul className="nav-links">
      <AboutTrialFormPopover
        id="popover-contact-video-section"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      {!auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <NavLink to="/sobre">
            <Button color="inherit">SOBRE</Button>
          </NavLink>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <Button
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            color="inherit"
          >
            CONTATO
          </Button>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <NavLink to="/login">
            <Button color="inherit">LOGIN</Button>
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && !!addToHomescreenEvent && (
        <li className="nav-link-vanish nav-link-vanish--highlighted">
          <NavLink to="/inicio">
            <Button
              color="inherit"
              onClick={() => {
                addToHomescreenPrompt(
                  addToHomescreenEvent,
                  setAddToHomescreenEvent
                );
              }}
            >
              <span className="nav-link-vanish-text">INSTALAR</span> &nbsp;{" "}
              <SystemUpdateAltRoundedIcon />
            </Button>
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li className="nav-link-vanish">
          <NavLink to="/inicio">
            <Button color="inherit" onClick={() => {}}>
              INÍCIO
            </Button>
          </NavLink>
        </li>
      )}
      {auth.isLoggedIn &&
        !auth.userIsAdmin &&
        auth.signupPlan !== "BASIC" &&
        (settingsCtx.individualPermissions
          ?.filter((ip) => {
            return ip.salesKanban;
          })
          ?.map((ip) => {
            return ip?.user;
          })
          .includes(auth.userId) ||
          auth.type !== "VENDOR") && (
          <li className="nav-link-vanish" onClick={() => {}}>
            <DesktopNavMenu
              menuTitle="VENDAS"
              textItem1="Nova Venda"
              textItem2="Funil de Vendas"
              textItem3="Vendas Finalizadas"
              link1={`/vendas/registro`}
              link2={`/vendas/funil/${auth.userId}`}
              link3={`/vendas/finalizadas`}
              icon1={<AddRoundedIcon fontSize="small" />}
              icon2={<NetworkWifiRoundedIcon fontSize="small" />}
              icon3={<AssignmentTurnedInRoundedIcon fontSize="small" />}
            />
          </li>
        )}
      {/* {auth.isLoggedIn && !auth.userIsAdmin && (
        <li
          onClick={() => {
            addToHomescreenPrompt(
              addToHomescreenEvent,
              setAddToHomescreenEvent
            );
          }}
        >
          <DesktopNavMenu
            menuTitle="CLIENTES"
            textItem1="Meus Clientes"
            textItem2="Registrar Cliente"
            textItem3="Exportar Clientes"
            textItem4="Upload de Clientes"
            link1={`/clientes/lista/${auth.userId}`}
            link2="/cliente/cadastro"
            function3={exportCustomerssData}
            link4="/upload-de-clientes"
            icon1={<GroupRoundedIcon fontSize="small" />}
            icon2={<PersonAddRoundedIcon fontSize="small" />}
            icon3={<FileCopyRoundedIcon fontSize="small" />}
            icon4={<BackupRoundedIcon fontSize="small" />}
          />
        </li>
      )} */}
      {/* {auth.isLoggedIn && !auth.userIsAdmin && (
        <li
          onClick={() => {
            addToHomescreenPrompt(
              addToHomescreenEvent,
              setAddToHomescreenEvent
            );
          }}
        >
          <DesktopNavMenu
            menuTitle="KITS"
            textItem1="Meus Kits"
            textItem2="Registrar Kit"
            textItem3="Upload de Kits"
            link1={`/kits/lista/${auth.userId}`}
            link2={
              auth.type !== "VENDOR" ||
              !settingsCtx.preferences?.vendors?.useManagerKits
                ? "/kits/cadastro/0"
                : null
            }
            link3={
              auth.type !== "VENDOR" ||
              !settingsCtx.preferences?.vendors?.useManagerKits
                ? "/upload-de-kits"
                : null
            }
            icon1={<ShoppingCartRoundedIcon fontSize="small" />}
            icon2={<AddShoppingCartRoundedIcon fontSize="small" />}
            icon3={<BackupRoundedIcon fontSize="small" />}
          />
        </li>
      )} */}
      {/* {auth.isLoggedIn && !auth.userIsAdmin && (
        <li
          onClick={() => {
            addToHomescreenPrompt(
              addToHomescreenEvent,
              setAddToHomescreenEvent
            );
          }}
        >
          <DesktopNavMenu
            menuTitle="PROPOSTAS"
            textItem1="Minhas Propostas"
            textItem2="Nova Proposta"
            textItem3={auth.type !== "VENDOR" ? "Personalização" : undefined}
            link1={`/propostas/lista/${auth.userId}`}
            link2="/proposta/gerar-proposta/dados-consumo/nova"
            link3={
              auth.type !== "VENDOR"
                ? `/personalizar-proposta/${auth.userId}`
                : undefined
            }
            icon1={<ListAltRoundedIcon fontSize="small" />}
            icon2={<PostAddRoundedIcon fontSize="small" />}
            icon3={
              auth.type !== "VENDOR" ? (
                <ColorLensRoundedIcon fontSize="small" />
              ) : undefined
            }
          />
        </li>
      )} */}
      {auth.isLoggedIn && (
        <IconButton
          className="nav-link-vanish"
          title={getAppStatusTitle(
            needCriticalUpdate,
            needUpdate,
            updateAvailable
          )}
          color="inherit"
          onClick={() => {
            history.push("/atualizacoes");
          }}
        >
          {getAppStatusIcon(needCriticalUpdate, needUpdate, updateAvailable)}
        </IconButton>
      )}
      <NotificationsMenu />
      {auth.isLoggedIn && !auth.userIsAdmin && (
        <li onClick={() => {}}>
          <DesktopNavMenu
            profile
            menuTitle="CONTA"
            textItem1="Dados da Empresa"
            textItem2="Curso Azume"
            textItem3="Financeiro"
            textItem4="Configurações"
            textItem5="Contato"
            textItem6="Sair"
            link1={`/conta/info/${auth.userId}`}
            // link2="/tutoriais"
            link3="/financeiro"
            link4="/configuracoes"
            link5="/contato"
            link6="user-loggout"
            icon1={<BusinessCenterRoundedIcon fontSize="small" />}
            icon2={<VideoLibraryRoundedIcon fontSize="small" />}
            icon3={<MonetizationOnRoundedIcon fontSize="small" />}
            icon4={<SettingsIcon fontSize="small" />}
            icon5={<ContactSupportRoundedIcon fontSize="small" />}
            icon6={<ExitToAppRoundedIcon fontSize="small" />}
            isConfigManager={isConfigManager}
          />
        </li>
      )}
    </ul>
  );
};
