import React, { useContext, Fragment } from "react";
import { ImportMapping } from "../../shared/components/ImportMapping/ImportMapping";
import { uploadSales } from "../api/salesFunnelAPI";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { AuthContext } from "../../shared/context/authContext";
import { ModalError } from "../../shared/components/UIElements/ModalError";

const availableProperties = [
  {
    value: "nome_cliente",
    aliases: ["nome_cliente", "cliente", "nome", "razao_social"],
    required: true,
  },
  {
    value: "email_cliente",
    aliases: ["email_cliente", "email", "e-mail"],
    required: false,
  },
  {
    value: "cpf_ou_cnpj_cliente",
    aliases: ["cpf_ou_cnpj_cliente", "cpf", "cnpj", "documento"],
    required: false,
  },
  {
    value: "rg_cliente",
    aliases: ["rg_cliente", "rg", "identidade"],
    required: false,
  },
  {
    value: "telefone_cliente",
    aliases: ["telefone_cliente", "telefone", "fone"],
    required: false,
  },
  {
    value: "whatsapp_cliente",
    aliases: ["whatsapp_cliente", "whatsapp", "celular"],
    required: false,
  },
  {
    value: "UF_cliente",
    aliases: ["UF_cliente", "uf", "estado"],
    required: false,
  },
  {
    value: "cidade_cliente",
    aliases: ["cidade_cliente", "cidade", "municipio"],
    required: false,
  },
  {
    value: "origem_cliente",
    aliases: ["origem_cliente", "origem", "fonte"],
    required: false,
  },
  {
    value: "endereco_cliente",
    aliases: ["endereco_cliente", "endereco"],
    required: false,
  },
  { value: "cep_cliente", aliases: ["cep_cliente", "cep"], required: false },
  {
    value: "logradouro_cliente",
    aliases: ["logradouro_cliente", "logradouro"],
    required: false,
  },
  {
    value: "numero_cliente",
    aliases: ["numero_cliente", "numero"],
    required: false,
  },
  {
    value: "bairro_cliente",
    aliases: ["bairro_cliente", "bairro"],
    required: false,
  },
  {
    value: "complemento_cliente",
    aliases: ["complemento_cliente", "complemento"],
    required: false,
  },
  {
    value: "profissao_cliente",
    aliases: ["profissao_cliente", "profissao"],
    required: false,
  },
  {
    value: "nacionalidade_cliente",
    aliases: ["nacionalidade_cliente", "nacionalidade"],
    required: false,
  },
  {
    value: "renda_mensal_cliente",
    aliases: ["renda_mensal_cliente", "faturamento_mensal_cliente"],
    required: false,
  },
  {
    value: "data_de_registro_cliente",
    aliases: ["data_de_registro_cliente", "data_registro_cliente"],
    required: false,
  },
  {
    value: "nome_responsavel_cliente",
    aliases: ["nome_responsavel_cliente"],
    required: false,
  },
  {
    value: "cpf_responsavel_cliente",
    aliases: ["cpf_responsavel_cliente"],
    required: false,
  },
  {
    value: "observacoes_cliente",
    aliases: ["observacoes_cliente", "observacoes_cliente"],
    required: false,
  },
  {
    value: "nome_negocio",
    aliases: ["nome_negocio", "negocio", "oportunidade"],
    required: true,
  },
  {
    value: "valor_negocio",
    aliases: ["valor_negocio", "valor", "preco"],
    required: true,
  },
  {
    value: "status_negocio",
    aliases: ["status_negocio", "status", "situacao"],
    required: false,
  },
  {
    value: "data_registro_negocio",
    aliases: ["data_registro_negocio", "data_registro", "data_criacao"],
    required: false,
  },
  {
    value: "data_finalizacao_negocio",
    aliases: ["data_finalizacao_negocio", "data_finalizacao", "data_conclusao"],
    required: false,
  },
  {
    value: "etapa_funil_negocio",
    aliases: ["etapa_funil_negocio", "etapa_funil", "estagio"],
    required: false,
  },
  {
    value: "observacoes_negocio",
    aliases: ["observacoes_negocio", "observacoes", "notas"],
    required: false,
  },
];

export const SalesImportMapping: React.FC = () => {
  const { sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleSubmit = async (
    mapping: Record<string, string>,
    fileContent: string
  ) => {
    try {
      await uploadSales({ sendRequest, auth, mapping, fileContent });
      return true;
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      return false;
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ImportMapping
        availableProperties={availableProperties}
        onSubmit={handleSubmit}
        importType="sales"
        title="Importação - Mapeamento de Vendas"
      />
    </Fragment>
  );
};
