import React, { useContext, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { FileUpload } from "../../shared/components/FormElements/FileUpload";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { Card } from "../../shared/components/UIElements/Card";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalSuccess } from "../../shared/components/UIElements/ModalSuccess";
import { useForm } from "../../shared/hooks/formHook";
import { ButtonOrangeDefault } from "../../shared/components/MUIThemed/ButtonOrangeDefault";
import { AuthContext } from "../../shared/context/authContext";
import { SettingsContext } from "../../shared/context/settingsContext";
import { useHttpClient } from "../../shared/hooks/httpHook";

import { CommonSimpleFormStyles } from "../../customer/pages/CommonSimpleFormStyles";

export const UploadPreSales: React.FC = () => {
  const auth = useContext(AuthContext);
  const settingsCtx = useContext(SettingsContext);
  const classes = CommonSimpleFormStyles();
  const { isLoading, error, success, clearError, clearSuccess, sendRequest } =
    useHttpClient();
  const { formState, inputHandler } = useForm(
    {
      file: {
        value: null,
        isValid: false,
      },
    },
    false,
    false
  );
  const isSalesManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.salesManager || false;

  const history = useHistory();

  const importTableHandler = async () => {
    if (auth.type !== "VENDOR" || isSalesManager) {
      const file = formState.inputs.file.value as File;
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const content = e.target?.result;
        history.push({
          pathname: "/upload-de-pre-vendas/mapeamento",
          state: {
            fileContent: content,
            fileName: file.name,
          },
        });
      };

      fileReader.readAsArrayBuffer(file);
    }
  };

  const downloadTemplate = () => {
    window.open(
      "https://azume-files.nyc3.cdn.digitaloceanspaces.com/uploads/archives/azume-modelo-upload-leads.xlsx",
      "_blank"
    );
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      {!isLoading && (
        <ModalSuccess
          success={success}
          onClear={clearSuccess}
          message="Upload realizado com sucesso!"
          redirect={`/pre-vendas/lista/${auth.userId}`}
        />
      )}
      <Card cardType="tinyCard">
        {isLoading && <LoadingSpinnerOverlayRegular />}
        <form
          className="simpleForm"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Typography className={classes.title} variant="h5">
            Upload de Pré-Vendas
          </Typography>
          <FileUpload
            id="file"
            onInput={inputHandler}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!formState.isValid}
            onClick={importTableHandler}
          >
            FAZER UPLOAD
          </Button>

          <ButtonOrangeDefault onClick={downloadTemplate}>
            DOWNLOAD DO MODELO DE UPLOAD
          </ButtonOrangeDefault>
        </form>
      </Card>
    </Fragment>
  );
};
